import React from "react"
import Img from "gatsby-image"
import classNames from "classnames"

import Layout from "layout/Layout"
import Container from "layout/Container"

import styles from "../utils/diagnosticSubsidy.module.scss"
import useDiagnosticSubsidyImages from "../hooks/useDiagnosticSubsidyImages"

const LOAAlreadyApproved = () => {
  const images = useDiagnosticSubsidyImages()

  return (
    <Layout>
      <Container isCentered>
        <div className={classNames(styles["diagnosticSubsidy__error"])}>
          <center>
            <Img fixed={images?.error?.childImageSharp?.fixed} />
            <h3 className="mt-1">Error</h3>
            <p>This diagnostic support has already been claimed.</p>
          </center>
        </div>
      </Container>
    </Layout>
  )
}

export default LOAAlreadyApproved
