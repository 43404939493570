import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

import styles from "./utils/elements.module.scss"

/**
 * @param {number} size integer, defaults to 2
 */

const Loading = ({ size }) => (
  <div className={classNames(`my-2`, styles["loading"])}>
    {/* Used .5 increments because 1rem is too big */}
    <div
      style={{
        height: `${(size || 2) * 0.5}rem`,
        width: `${(size || 2) * 0.5}rem`,
      }}
    />
    <div
      style={{
        height: `${(size || 2) * 0.5}rem`,
        width: `${(size || 2) * 0.5}rem`,
      }}
    />
    <div
      style={{
        height: `${(size || 2) * 0.5}rem`,
        width: `${(size || 2) * 0.5}rem`,
      }}
    />
  </div>
)

Loading.propTypes = {
  size: PropTypes.string,
}

export default Loading
