import React from "react"
import classNames from "classnames"

import Layout from "layout/Layout"
import Loading from "elements/Loading"
import Container from "layout/Container"

import styles from "../utils/diagnosticSubsidy.module.scss"

const LOALoading = () => {
  return (
    <Layout>
      <Container isCentered>
        <div className={classNames(styles["diagnosticSubsidy__error"])}>
          <Loading />
        </div>
      </Container>
    </Layout>
  )
}

export default LOALoading
