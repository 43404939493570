import React, { Fragment } from "react"
import QRCode from "qrcode.react"
import classNames from "classnames"

import Message from "elements/Message"
import Section from "elements/Section"
import FormInput from "elements/Form/FormInput"
import UploadGuidelines from "elements/UploadGuidelines"
import RequirementsMessage from "elements/UploadDocuments/RequirementsMessage"
import UploadDocumentDropzone from "elements/UploadDocuments/UploadDocumentDropzone"

import styles from "../utils/diagnosticSubsidy.module.scss"
import { dropzoneMessages } from "../utils/dropzoneMessages"

const CoordinatorCodeInput = () => {
  return (
    <FormInput
      name="labCoordinatorCode"
      label={
        <span>
          Laboratory Verification Code{" "}
          <br className="is-hidden-tablet is-hidden-desktop" />
          <span className="is-italic has-text-grey">(for lab use only)</span>
        </span>
      }
      helper="The laboratory representative will input a verification code to confirm that your support has been claimed."
      placeholder="1234567"
      isRequired
    />
  )
}

const LOAFormLabCoordinator = ({ location }) => {
  return (
    <Section isRequired title="How to claim">
      <div className="columns is-vcentered mb-1 mb-0">
        <div className="column is-8">
          <Message color="light">
            <ul className={classNames(styles["diagnosticSubsidy__mechanics"])}>
              <li className="mb-1">
                Present your LOA, government-issued ID, and laboratory request
                (printed or ePrescription) to your pre-selected laboratory upon
                availment
              </li>
              <li>
                Show the QR code{" "}
                <span className="is-hidden-tablet is-hidden-desktop">
                  below
                </span>{" "}
                to the laboratory representative
              </li>
            </ul>
          </Message>
          <div className="is-hidden-mobile">
            <CoordinatorCodeInput />
          </div>
        </div>
        <div className="column is-justify-content-center is-flex is-flex-direction-column is-align-items-center">
          <QRCode
            value={location.href}
            size={200}
            fgColor="#003366"
            className={classNames(styles["diagnosticSubsidy__qr"])}
          />
          <div className="is-hidden-tablet is-hidden-desktop mt-3">
            <CoordinatorCodeInput />
          </div>
        </div>
      </div>
      <div>
        <UploadGuidelines />
        <RequirementsMessage
          header="Please take a photo of the patient’s laboratory request. The laboratory request must include:"
          requirements={dropzoneMessages.labRequest}
        />
        <UploadDocumentDropzone
          docType="lab"
          label="Take a photo or choose a file"
          icon="document"
          maxFiles={6}
        />
      </div>
    </Section>
  )
}

export default LOAFormLabCoordinator
