import React from "react"
import classNames from "classnames"

import Message from "elements/Message"
import Section from "elements/Section"

import mechanics from "../utils/mechanics.json"
import styles from "../utils/diagnosticSubsidy.module.scss"

const LOAFormMechanics = () => {
  return (
    <Section isRequired title="Mechanics" className="mt-1 mb-2">
      <Message color="light">
        <ul className={classNames(styles["diagnosticSubsidy__mechanics"])}>
          {mechanics.map(formMechanics => (
            <li className="mb-1">{formMechanics}</li>
          ))}
        </ul>
      </Message>
    </Section>
  )
}

export default LOAFormMechanics
