import React from "react"
import moment from "moment"
import classNames from "classnames"

import Section from "elements/Section"

import styles from "../utils/diagnosticSubsidy.module.scss"

const LOAFormPersonalDetails = ({ enrollmentDetails, ticket }) => {
  return (
    <Section isRequired title="Personal Details" className="mt-1 mb-2">
      <table className="table is-fullwidth is-size-5">
        <tbody>
          <tr>
            <td className="has-text-weight-bold">Name</td>
            <td className={classNames(styles["diagnosticSubsidy__tableData"])}>
              {enrollmentDetails?.formData?.firstName}{" "}
              {enrollmentDetails?.formData?.lastName}
            </td>
          </tr>
          <tr>
            <td className="has-text-weight-bold">Reference Code</td>
            <td className={classNames(styles["diagnosticSubsidy__tableData"])}>
              {enrollmentDetails?.formData?.referenceCode}
            </td>
          </tr>
          <tr>
            <td className="has-text-weight-bold">Diagnostic Subsidy</td>
            <td className={classNames(styles["diagnosticSubsidy__tableData"])}>
              Up to Php {enrollmentDetails?.formData?.packageAvailed?.amount}
            </td>
          </tr>
          <tr>
            <td className="has-text-weight-bold">Diagnostic Test Package</td>
            <td className={classNames(styles["diagnosticSubsidy__tableData"])}>
              {enrollmentDetails?.formData?.packageAvailed?.testPackage}
            </td>
          </tr>
          <tr>
            <td className="has-text-weight-bold">Selected Partner Lab</td>
            <td className={classNames(styles["diagnosticSubsidy__tableData"])}>
              {enrollmentDetails?.formData?.packageAvailed?.name}
            </td>
          </tr>
          <tr>
            <td className="has-text-weight-bold">Valid Until</td>
            <td className={classNames(styles["diagnosticSubsidy__tableData"])}>
              {moment(
                enrollmentDetails?.formData?.packageAvailed?.validUntil
              ).format("MMM DD, YYYY")}
            </td>
          </tr>
        </tbody>
      </table>
    </Section>
  )
}

export default LOAFormPersonalDetails
